/* eslint-disable default-case */
import React from "react";

export const planPrice = {
  weekly: 19.99,
  monthly: 79.99,
  yearly: 599.99,
  "weekly-premium": 137.99,
  "monthly-premium": 499.98,
  "yearly-premium": 3999.99,
  "nba-playoff": 499.99,
  "wnba-season": 199.99,
  "day-pass": 9.99,
  "content-monthly": 9.99,
};

const teamStandingCSVSorting = (arr) => {
  return arr.map((dt) => {
    let updatedObj = {};
    updatedObj["Team_Name"] = dt["Team_Name"];
    updatedObj["Conference"] = dt["Conference"];
    updatedObj["Adjusted_Shot_Quality"] = dt["Adjusted_Shot_Quality"];
    updatedObj["Offensive_Shot_Quality"] = dt["Offensive_Shot_Quality"];
    updatedObj["Offensive_Shot_Quality_Rank_Visual"] = dt["Offensive_Shot_Quality_Rank_Visual"];
    updatedObj["Defensive_Shot_Quality"] = dt["Defensive_Shot_Quality"];
    updatedObj["Defensive_Shot_Quality_Rank_Visual"] = dt["Defensive_Shot_Quality_Rank_Visual"];
    updatedObj["Rim_and_3_rate"] = dt["rim_and_3_rate"] || "N/A";
    updatedObj["Actual_Record"] = dt["Actual_Record"].replace(/ /gi, "");
    updatedObj["Actual_Win_Percentage"] = dt["Actual_Win_Percentage"];
    updatedObj["SQ_Record"] = dt["SQ_Record"].replace(/-/gi, "");
    updatedObj["SQ_Win_Percentage"] = dt["SQ_Win_Percentage"];
    updatedObj["Record_Luck"] = dt["Record_Luck"];

    return updatedObj;
  });
};

const playerStatsCSVSorting = (arr) => {
  return arr.map((dt) => {
    let updatedObj = {};
    updatedObj["Players"] = dt["Players"];
    updatedObj["Team"] = dt["Team"];
    updatedObj["Possessions"] = dt["Possessions"];
    updatedObj["SQ PPP"] = dt["SQ PPP"];
    updatedObj["SQ PPP Rank"] = dt["SQ PPP Rank"];
    updatedObj["Good_Possession_Rate"] = dt["Good_Possession_Rate"];
    updatedObj["Bad_Possession_Rate"] = dt["Bad_Possession_Rate"];
    updatedObj["Rim_and_3_rate"] = dt["Rim_and_3_rate"] || "N/A";
    updatedObj["Assists_per_game"] = dt["Assists_per_game"];
    updatedObj["Actual_Win_Percentage"] = dt["Actual_Win_Percentage"];
    updatedObj["SQ_Assists_per_game"] = dt["SQ_Assists_per_game"];
    updatedObj["SQ_Passing_Points_Created_per_game"] = dt["SQ_Passing_Points_Created_per_game"];

    return updatedObj;
  });
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const Utils = {
  higherGradientColors: [
    "#ff0000c4",
    "#ff1212c7",
    // "#ff4040bf",
    "#ff9e9e",
    "#ffe0e0",
    "#fffb0030",
    "#e2ff4f6e",
    "#abff66e0",
    "#abff66c9",
    "#5bff45cc",
    "#32fc47",
    "#00ff00",
  ],
  capitalize(str, lower = false) {
    return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
  },
  getAsset(name) {
    return require(`../assets/images/${name}`);
  },
  async readCsv(path) {
    try {
      const link = require("../assets/csv/" + path);

      return link;
    } catch (e) {
      console.error(e);
    }
  },
  processData(allText) {
    let allTextLines = allText.split(/\r\n|\n/);
    let headers = allTextLines[0].split(",");
    let lines = [];

    for (var i = 1; i < allTextLines.length; i++) {
      let data = allTextLines[i].split(",");
      if (data.length == headers.length) {
        let line = {};
        for (var j = 0; j < headers.length; j++) {
          let key = this.getValue(headers[j]);
          key = key === "" ? "key" : key.trim();
          line[key] = this.getValue(data[j]);
        }
        lines.push(line);
      }
    }
    return { header: headers, data: lines };
  },
  getValue(val) {
    try {
      return JSON.parse(val);
    } catch (e) {
      return val;
    }
  },
  dynamicsort(property, order, keySumm, bettingName) {
    var sort_order = 1;
    if (order === "desc") {
      sort_order = -1;
    }

    return function (a, b) {
      if (property == "summaryColumns" && keySumm) {
        if (a[keySumm.key1] + a[keySumm.key2] < b[keySumm.key1] + b[keySumm.key2]) {
          return -1 * sort_order;
          // a should come after b in the sorted order
        } else if (a[keySumm.key1] + a[keySumm.key2] > b[keySumm.key1] + b[keySumm.key2]) {
          return 1 * sort_order;
          // a and b are the same
        } else {
          return 0 * sort_order;
        }
      }

      if (property == "Final Score" && keySumm) {
        if (a[keySumm.key1] + a[keySumm.key2] < b[keySumm.key1] + b[keySumm.key2]) {
          return -1 * sort_order;
          // a should come after b in the sorted order
        } else if (a[keySumm.key1] + a[keySumm.key2] > b[keySumm.key1] + b[keySumm.key2]) {
          return 1 * sort_order;
          // a and b are the same
        } else {
          return 0 * sort_order;
        }
      }

      if (property == "SQ Score" && keySumm) {
        if (a[keySumm.key1] + a[keySumm.key2] < b[keySumm.key1] + b[keySumm.key2]) {
          return -1 * sort_order;
          // a should come after b in the sorted order
        } else if (a[keySumm.key1] + a[keySumm.key2] > b[keySumm.key1] + b[keySumm.key2]) {
          return 1 * sort_order;
          // a and b are the same
        } else {
          return 0 * sort_order;
        }
      }
      if (property === "Date & Time") {
        return new Date(a["Date"].split("/").reverse()) < new Date(b["Date"].split("/").reverse())
          ? -1 * sort_order
          : new Date(a["Date"].split("/").reverse()) > new Date(b["Date"].split("/").reverse())
          ? 1 * sort_order
          : 0 * sort_order;
      }

      if (property === "date" || property === "Date") {
        return new Date(a[property].split("/").reverse()) < new Date(b[property].split("/").reverse())
          ? -1 * sort_order
          : new Date(a[property].split("/").reverse()) > new Date(b[property].split("/").reverse())
          ? 1 * sort_order
          : 0 * sort_order;
      }

      // dateTime = "2024-03-11 07:00:00 PM"
      if (property === "dateTime") {
        return new Date(a[property]) < new Date(b[property])
          ? -1 * sort_order
          : new Date(a[property]) > new Date(b[property])
          ? 1 * sort_order
          : 0 * sort_order;
      }

      if (property === "player") {
        let sortOrder = 1;

        if (a.player < b.player) {
          return -sortOrder;
        }
        if (a.player > b.player) {
          return sortOrder;
        }
        return 0;
      }

      if (property === "Home") {
        if (order === "desc") {
          return -1 * (a.home_team?.localeCompare(b.home_team) || a.Home?.localeCompare(b.Home));
        }
        return a.home_team?.localeCompare(b.home_team) || a.Home?.localeCompare(b.Home);
      }

      if (property === "Away") {
        if (order === "desc") {
          return -1 * (a.away_team?.localeCompare(b.away_team) || a.Away?.localeCompare(b.Away));
        }
        return a.away_team?.localeCompare(b.away_team) || a.Away?.localeCompare(b.Away);
      }

      // if (property === "Opp_Score" || property === "Team_Score") {
      //   return order.sort(
      //     (model, nextModel) => model.Opp_Score - nextModel.Opp_Score
      //   );
      // }
      // a should come before b in the sorted order

      if (a[property] < b[property]) {
        return -1 * sort_order;
        // a should come after b in the sorted order
      } else if (a[property] > b[property]) {
        return 1 * sort_order;
        // a and b are the same
      } else {
        return 0 * sort_order;
      }
    };
  },
  toPercent(rate) {
    return `${parseInt(rate * 100)}%`;
  },
  toPercentFloat(rate, fixed = 1) {
    return `${(parseFloat(rate) * 100).toFixed(fixed)}%`;
  },
  hasTrial(user) {
    return user && (new Date() - new Date(Date.parse(user.registered_date))) / (1000 * 60 * 60 * 24) < 2;
  },
  getTrialDaysLeft(user) {
    return Math.ceil(2 - (new Date() - new Date(Date.parse(user.registered_date))) / (1000 * 60 * 60 * 24));
  },
  toFixedNum(num) {
    if (typeof num === "number") {
      return num.toFixed(2);
    } else {
      return "";
    }
  },
  getBgColor(avgValue, currValue, uptoFixedPoint = 2, isReverse, minValue, maxValue) {
    if (uptoFixedPoint > 2) {
      // console.log("Getting color for conf");
      return this.forConf(minValue, maxValue, currValue, isReverse);
    } else {
      // console.log("Getting color for other page");
      return this.forOtherPages(currValue);
    }
  },
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  confGradient({ min, max, average, currValue, isReverse = false }) {
    let divisor = (max - min) / 10;
    let colorIndex;
    colorIndex = Number.parseInt((currValue - min) / divisor);

    if (isReverse === true) {
      if (Number.parseFloat(currValue) >= average) {
        return this.higherGradientColors[0 + Number.parseInt(colorIndex / 2)];
      } else {
        return this.higherGradientColors[this.higherGradientColors.length - colorIndex];
      }
    }

    if (isReverse === false) {
      if (Number.parseFloat(currValue) >= average) {
        return this.higherGradientColors[this.higherGradientColors.length - colorIndex];
      } else {
        return this.higherGradientColors[0 + Number.parseInt(colorIndex / 2)];
      }
    }
  },
  forOtherPages(currValue) {
    let ceiledValue = parseFloat((Math.ceil(currValue * 20) / 20).toFixed(2));
    let maxVal = 1.4;
    let minVal = 0.6;
    let divisor = 1 / 11;
    if (currValue >= maxVal) {
      return this.higherGradientColors[this.higherGradientColors.length - 1];
    } else if (currValue <= minVal) {
      return this.higherGradientColors[0];
    } else {
      let colorIndex = parseInt((currValue - minVal) / divisor);
      return this.higherGradientColors[colorIndex];
    }
  },
  forConf(minVal, maxVal, currValue, isReverse) {
    let ceiledValue = parseFloat((Math.ceil(currValue * 20) / 20).toFixed(2));

    let divisor = (maxVal - minVal) / 10;
    let colorIndex;
    if (currValue >= maxVal) {
      colorIndex = this.higherGradientColors.length - 1;
    } else if (currValue <= minVal) {
      colorIndex = 0;
    } else {
      colorIndex = parseInt((currValue - minVal) / divisor);
    }
    return isReverse
      ? this.higherGradientColors[this.higherGradientColors.length - colorIndex]
      : this.higherGradientColors[colorIndex];
  },
  useStickyState(defaultValue, key) {
    const [value, setValue] = React.useState(() => {
      if (global.window) {
        var stickyValue = window.localStorage.getItem(key);

        return stickyValue !== null && stickyValue !== "undefined" ? JSON.stringify(stickyValue) : defaultValue;
      }
    });
    React.useEffect(() => {
      window.localStorage.setItem(key, JSON.parse(value));
    }, [key, value]);
    return [value, setValue];
  },
  jsonToCsvWithFilename(data, filename = "shotquality.csv") {
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(data[0]);
    let csv = data.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(","));
    csv.unshift(header.join(","));
    csv = csv.join("\r\n");
    const csvContent = "data:text/csv;charset=utf-8," + csv;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link); // Required for FF
    link.click();
  },
  jsonToCsv(data, pageName = undefined, filename = "shot_quality.csv", colOrder = null) {
    const items =
      pageName === "TeamStandings"
        ? teamStandingCSVSorting(data)
        : pageName === "playerStats"
        ? playerStatsCSVSorting(data)
        : data;
    // const items = data;
    const replacer = (key, value) => (value === null ? "" : value);
    const header = colOrder || Object.keys(items[0]);
    let csv = items.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(","));
    csv.unshift(header.join(","));
    csv = csv.join("\r\n");
    const csvContent = "data:text/csv;charset=utf-8," + csv;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link); // Required for FF
    link.click();
  },
  getTableHeaderStyle() {
    return document.getElementsByClassName("topbar-warning").length > 0 ? "sticky-th-1" : "sticky-th-2";
  },
  getDeviceType() {
    // return window?.outerWidth < 768 ? "Mobile" : "Desktop"
    if (typeof window !== "undefined") {
      return window?.outerWidth < 768 ? "Mobile" : "Desktop";
    } else {
      return "Desktop";
    }
  },

  // getVisibleCardsPerViewPort(arrayLength) {
  //   if (arrayLength >= 5) {
  //     return window.outerWidth < 768 ? 1 : window.outerWidth < 980 ? 3 : 5;
  //   } else if (arrayLength === 4) {
  //     return window.outerWidth < 768 ? 1 : window.outerWidth < 980 && window.outerWidth > 768 ? 3 : 4;
  //   } else if (arrayLength === 3) {
  //     return window.outerWidth < 768 ? 1 : window.outerWidth < 980 && window.outerWidth > 768 ? 2 : 3;
  //   } else if (arrayLength === 2) {
  //     return window.outerWidth < 768 ? 1 : window.outerWidth < 980 && window.outerWidth > 768 ? 2 : 2;
  //   }
  // },
  getGradientsForPercentile(value) {
    // Define the color stops and their corresponding values
    const colorStops = [
      { value: 0, color: [234, 90, 46] }, // dark red
      { value: 20, color: [240, 90, 50] }, // dark red
      { value: 30, color: [245, 90, 90] }, // little fault red
      { value: 40, color: [255, 200, 200] }, // somewhat red but most white
      { value: 50, color: [255, 255, 255] }, // white
      { value: 60, color: [200, 255, 230] }, // little green but most white
      { value: 70, color: [100, 235, 150] }, // more green
      { value: 80, color: [100, 220, 150] }, // green
      { value: 100, color: [100, 200, 150] }, // dark green
    ];

    // Check if the input value is out of range
    if (value <= colorStops[0].value) {
      return `rgb(${colorStops[0].color.join(", ")})`;
    }
    if (value >= colorStops[colorStops.length - 1].value) {
      return `rgb(${colorStops[colorStops.length - 1].color.join(", ")})`;
    }

    // Find the two color stops that the value falls between
    let i = 0;
    while (i < colorStops.length - 1 && value >= colorStops[i + 1].value) {
      i++;
    }

    // Find the proportion of the way between the two color stops
    const proportion = (value - colorStops[i].value) / (colorStops[i + 1].value - colorStops[i].value);

    // Interpolate between the two colors
    const color = colorStops[i].color.map((channel, index) =>
      Math.round(channel + proportion * (colorStops[i + 1].color[index] - channel))
    );

    // Return the color as an RGB string
    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
  },
  getGradients({ rank, leagueType, color1 = { r: 101, g: 186, b: 61 }, color2 = { r: 234, g: 77, b: 64 } }) {
    // Updated Gradient version
    let limit = leagueType === "nba" ? 30 : 347;
    let middleIndex = Number.parseInt(limit / 2);
    let multiple = limit / 500;

    switch (leagueType) {
      case "nba":
        multiple = limit / 22;

        if (rank <= middleIndex) {
          return `rgba(${color1["r"]}, ${color1["g"]}, ${color1["b"]}, ${1 - (rank * multiple) / 20})`;
        } else {
          return `rgba(${color2["r"]}, ${color2["g"]}, ${color2["b"]}, ${(1 - (middleIndex - rank) * multiple) / 20})`;
        }
      case "college_mens":
        multiple = limit / 500;
        if (rank < middleIndex) {
          return `rgba(${color1["r"]}, ${color1["g"]}, ${color1["b"]}, ${(1 - (rank * multiple) / 125).toFixed(4)})`;
        } else {
          return `rgba(${color2["r"]}, ${color2["g"]}, ${color2["b"]}, ${(
            (1 - (middleIndex - rank) * multiple) /
            125
          ).toFixed(4)})`;
        }
      case "college_womens":
        multiple = limit / 500;
        if (rank < middleIndex) {
          return `rgba(${color1["r"]}, ${color1["g"]}, ${color1["b"]}, ${(1 - (rank * multiple) / 125).toFixed(4)})`;
        } else {
          return `rgba(${color2["r"]}, ${color2["g"]}, ${color2["b"]}, ${(
            (1 - (middleIndex - rank) * multiple) /
            125
          ).toFixed(4)})`;
        }
      case "wnba":
        multiple = limit / 500;
        if (rank < middleIndex) {
          return `rgba(${color1["r"]}, ${color1["g"]}, ${color1["b"]}, ${(1 - (rank * multiple) / 125).toFixed(4)})`;
        } else {
          return `rgba(${color2["r"]}, ${color2["g"]}, ${color2["b"]}, ${(
            (1 - (middleIndex - rank) * multiple) /
            125
          ).toFixed(4)})`;
        }
    }
  },
  getConfGradient({ rank, conferenceTeamCount }) {
    let limit = conferenceTeamCount;
    let middleIndex = Number.parseInt(limit / 2);
    let multiple = limit / (limit * (limit / 2));

    if (rank <= middleIndex) {
      return `rgba(101, 186, 61, ${1 - rank * multiple})`;
    } else {
      return `rgba(234, 77, 64, ${rank * multiple - 1})`;
    }
  },
  getMean(arrObj = []) {
    if (arrObj.length > 0) {
      const average = {};
      const sum = [...arrObj].reduce((aObj, bObj) => {
        const reducedObj = {};
        const numOnlyA = Object.keys(aObj).filter((a) => typeof aObj[a] === "number");

        numOnlyA.forEach((key) => {
          reducedObj[key] = aObj[key] + bObj[key];
        });

        return reducedObj;
      });

      if (Object.keys(sum).length > 0) {
        Object.keys(sum).forEach((key) => {
          average[key] = (sum[key] / arrObj.length).toFixed(2);
        });
      }

      return average;
    } else {
      return {};
    }
  },
  throttle(callback, limit) {
    let waiting = false;
    return function () {
      if (!waiting) {
        callback.apply(this, arguments);
        waiting = true;
        setTimeout(function () {
          waiting = false;
        }, limit);
      }
    };
  },
  getTeamRank(teamStats, teamName) {
    const getTeamRank = (teamName, sortedArr) => {
      return sortedArr.findIndex((elem) => elem["Team_Name"] === teamName) + 1;
    };

    let sortedOffensive = [...teamStats.sort(Utils.dynamicsort("Offensive_Shot_Quality", "desc"))];
    let sortedDefensive = [...teamStats.sort(Utils.dynamicsort("Defensive_Shot_Quality", "asc"))];
    let sortedRimAndRate3 = [...teamStats.sort(Utils.dynamicsort("rim_and_3_rate", "desc"))];

    let sortedAndRankedResponse = teamStats
      .sort(Utils.dynamicsort("Adjusted_Shot_Quality", "desc"))
      .map((elem, index) => {
        return {
          ...elem,

          adjSQRank: index + 1,
          offensiveRank: getTeamRank(elem["Team_Name"], sortedOffensive),
          defensiveRank: getTeamRank(elem["Team_Name"], sortedDefensive),
          rimAndRate3Rank: getTeamRank(elem["Team_Name"], sortedRimAndRate3),
        };
      })
      .filter((data) => data["Team_Name"] === teamName);

    if (sortedAndRankedResponse.length > 0) {
      return sortedAndRankedResponse[0];
    } else {
      return {};
    }
  },
  async getTeamLogoColor(teamName = "") {
    const filteredTeam = await Utils.readCsv("teams/Color_Code_for_Teams.csv").then((colorCSV) => {
      return colorCSV.data.filter((x) => x.Teams == teamName);
    });

    if (filteredTeam.length > 0) {
      return filteredTeam[0]["Colors"];
    } else {
      return "";
    }
  },
  hasStandardPlan(user) {
    // console.log({ user }, "hasStandardPlan")
    if (user && user?.accounts) {
      for (let account of user?.accounts) {
        if (
          account?.account_type?.account_name?.match("Tier 1") ||
          account?.account_type?.account_name?.match("Standard")
        ) {
          return true;
          // if (account?.account_type?.league?.match(leagueType)) {
          // }
        }
      }
    }
    return false;
  },
  hasPremiumPlan(user) {
    if (user && user?.accounts) {
      for (let account of user?.accounts) {
        if (
          // TODO: change this when implementing for standard accounts
          account?.account_type?.account_name?.match("Tier 2") ||
          account?.account_type?.account_name?.match("Premium") ||
          account?.account_type?.account_name?.match("Premium") ||
          account?.account_type?.account_name?.match("Trader") ||
          account?.account_type?.account_name?.match("JBL Trader")
        ) {
          return true;
          // if (account?.account_type?.league?.match(leagueType)) {
          // }
        }
      }
    }
    return false;
  },
  isTrader(user) {
    if (user && user?.accounts) {
      for (let account of user?.accounts) {
        if (
          account?.account_type?.account_name?.match("Trader") ||
          account?.account_type?.account_name?.match("JBL Trader")
        ) {
          return true;
          // if (account?.account_type?.league?.match(leagueType)) {
          // }
        }
      }
    }
    return false;
  },
  isJBLTrader(user) {
    if (user && user?.accounts) {
      for (let account of user?.accounts) {
        if (account?.account_type?.account_name?.match("JBL Trader")) {
          return true;
        }
      }
    }
    return false;
  },
  hasPremiumPlanV2(user) {
    if (user && user?.accounts) {
      for (let account of user?.accounts) {
        if (account?.account_type?.account_name?.match("Premium")) {
          return true;
          // if (account?.account_type?.league?.match(leagueType)) {
          // }
        }
      }
    }
    return false;
  },
  getPlans(plans) {
    const planNames = ["ShotqualityBets", "NBA", "NCAAM"];

    const bothPlans = plans.filter((plan) => plan.name.startsWith(planNames[0]));
    const nbaPlans = plans.filter((plan) => plan.name.startsWith(planNames[1]) && !plan.name.startsWith(planNames[0]));
    const ncaamPlans = plans.filter(
      (plan) => plan.name.startsWith(planNames[2]) && !plan.name.startsWith(planNames[0])
    );
    return { bothPlans, nbaPlans, ncaamPlans };
  },
  setPreLoginUrl(url) {
    if (typeof window !== "undefined") {
      localStorage.setItem("preLoginUrl", window.location.pathname);
    }
  },
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  swapObjectKeyValue(obj) {
    return Object.keys(obj).reduce((acc, key) => {
      acc[obj[key]] = key;
      return acc;
    }, {});
  },
};

export const validateEmail = (email) => {
  let lastAtPos = email.lastIndexOf("@");
  let lastDotPos = email.lastIndexOf(".");
  if (
    !(
      lastAtPos < lastDotPos &&
      lastAtPos > 0 &&
      email.indexOf("@@") == -1 &&
      lastDotPos > 2 &&
      email.length - lastDotPos > 2
    )
  ) {
    return false;
  }
  return true;
};

export const getOnlyCapLetters = (str) => {
  return str.replace(/[^A-Z]/g, "");
};

export const calculateDiscount = (coupon, plan) => {
  if (coupon["amount_off"]) {
    return coupon["amount_off"];
  } else if (coupon["percent_off"]) {
    //round up to 0th decimal place
    let discount = (planPrice[plan] * coupon["percent_off"]) / 100;
    return discount.toFixed(0);
  }
  return planPrice[plan];
};

export default Utils;
